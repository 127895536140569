import ctaFrame from '@/assets/images/cta-frame.webp';
import topaz from '@/assets/images/topaz.webp';
import coins from '@/assets/images/coins.png';
import circle from '@/assets/images/circle.png';
import { useLizcoinCTA } from '@/components/sections/lizcoin/cta/provider';
import { motion, useAnimate } from 'framer-motion';
import { useEffect } from 'react';
import { Button, buttonVariants } from '@/components/ui/button';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Icons } from '@/components/ui/icons';

const StageText = ({ text }: { text: string }) => {
    return <span className='text-white font-figtree font-bold text-xs lg:text-base leading-[15px] line-clamp-none lg:leading-5 lg:line-clamp-3 lg:min-h-[70px]'>{text}</span>
}

const StageCTA = ({ text, onClick }: { text: string | JSX.Element, onClick: any }) => {
    return <motion.button
        onClick={onClick}
        className={cn(buttonVariants({ variant: 'default' }), 'absolute left-0 -bottom-1.5 lg:-bottom-4 bg-cta border-[#F9EC31] border-[3px] text-[#1F1D40] font-figtree !font-bold shadow-cta px-6 lg:py-[10px] h-[40px] lg:h-[48px] !text-base lg:!text-[18.5px] leading-7 hover:scale-[1.02] !rounded-[66px]')}
    >
        <span>{text}</span>
    </motion.button>
}

const StageContent = () => {

    const { stage, setStage, setClosed } = useLizcoinCTA();

    const stakeLizcoin = () => {
        window.open("https://staking.lizlounge.io/", "_blank");
        setStage(2);
    }

    const shareLizcoin = () => {
        window.open("https://x.com/intent/tweet?text=https://lizlabs.io/lizcoin", "_blank");
        setStage(0);
        setClosed(true);
    }

    if (stage === 1) {
        return <>
            <StageText text="Hey, are you that smart Lizard that just bought some of my shiny Lizcoins?! Why not stake those juicy coins to earn even more rewards!" />
            <StageCTA text="Stake my $LIZ" onClick={stakeLizcoin}/>
        </>
    }

    if (stage === 2) {
        return <>
            <StageText text="Welcome Back! Good job locking up your $LIZ! Why not share on X to get your friends in on the deal?!" />
            <StageCTA text={<span className='flex justify-center items-center gap-x-2'>Share on <Icons.twitterX className='w-6 h-6'/></span>} onClick={shareLizcoin}/>
        </>
    }

    return null;
}

export const LizcoinCTABanner = () => {

    const [scope, animate] = useAnimate();
    const { setStage, stage, closed, setClosed } = useLizcoinCTA();

    useEffect(() => {
        // setStage(0)
        // setClosed(false)
        if (closed) return;
        if (stage !== 0) {
            animate(".cta-wrapper", { opacity: 1, y: 0 }, { duration: 0.5 })
        }
    }, [stage, closed]);

    if (closed || stage === 0) return null;

    return <div ref={scope} className='hidden lg:flex fixed -bottom-0 lg:bottom-10 right-8 lg:right-20 w-full max-w-[300px] lg:max-w-[617px] min-h-[136px] z-20'>
        <div className='relative w-full h-full cta-wrapper opacity-0 min-h-[75px] lg:min-h-[136px] drop-shadow-lizcoin-cta'>
            <div 
                className='absolute bottom-0 right-0 lg:max-w-[551px] w-full h-full bg-contain bg-no-repeat pl-8 pt-1 pr-16 lg:pl-[57px] lg:pr-[131px] lg:pt-[22px]'
                style={{ backgroundImage: `url(${ctaFrame})` }}
            >
                <img src={coins} className='absolute -right-12 -bottom-2 lg:bottom-[4px] max-w-[160px] lg:max-w-[283px]' />
                <img src={topaz} className='absolute -right-12 lg:-right-20 -bottom-4 max-w-[120px] lg:max-w-[209px]' />
                <Button
                    className='rounded-full !w-8 !h-8 lg:!w-[45px] lg:!h-[45px] absolute -left-4 -top-4 lg:-left-[22px] lg:-top-[22px] p-0 bg-cover bg-no-repeat bg-center'
                    style={{ backgroundImage: `url(${circle})` }}
                    onClick={() => setClosed(true)}
                >
                    <X className='w-6 h-6' color='white' />
                </Button>
                <StageContent />
            </div>
            
        </div>
    </div>
}