import React, { createContext, useCallback, useContext, useState } from 'react';

interface CTAContextType {
    closed: boolean;
    setClosed: (s: boolean) => void;
    stage: number;
    setStage: (s: number) => void;
}

const defaultValues = {
    closed: false,
    setClosed: () => {},
    stage: 0,
    setStage: () => {}
}

const LizcoinCTAContext = createContext<CTAContextType>(defaultValues);

export const useLizcoinCTA = () => {
    return useContext(LizcoinCTAContext);
}

export const LizcoinCTAProvider = ({ children }: { children: React.ReactNode }) => {

    const localStage = localStorage.getItem("lizcoin_cta_stage");
    const localClosed = localStorage.getItem("lizcoin_cta_stage_closed");

    const [closed, updateClosed] = useState(localClosed ? localClosed === "true" : defaultValues.closed);
    const [stage, updateStage] = useState(localStage ? Number(localStage) : defaultValues.stage);

    const setClosed = (s: boolean) => {
        updateClosed(s);
        localStorage.setItem("lizcoin_cta_stage_closed", String(s));
        updateStage(0);
    }

    const setStage = useCallback((newStage: number) => {
        localStorage.setItem("lizcoin_cta_stage", String(newStage));
        updateStage(newStage);
    }, [updateStage])

    return (
        <LizcoinCTAContext.Provider value={{
            stage,
            setStage,
            closed,
            setClosed
        }}>
            {children}
        </LizcoinCTAContext.Provider>
    );
};